import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import { useShoppingCart } from "use-shopping-cart"

export default function Products(props) {
  const { addItem } = useShoppingCart()
  return (
    <StaticQuery
      query={graphql`
        query ProductsList {
          products: allStripeProduct(
            sort: { order: ASC, fields: metadata___order }
          ) {
            edges {
              node {
                images
                id
                active
                name
                description
                metadata {
                  available
                  url_slug
                }
              }
            }
          }
        }
      `}
      render={({ products }) => (
        <div className="">
          <div className=" ">
            <div
              role="contentinfo"
              className="flex items-center justify-center"
            >
              <hr className="w-2.5 h-16 bg-lime-700" />
              <h1 className=" focus:outline-none text-4xl font-bold text-gray-900 pl-7">
                Shop
                <span className="text-2xl font-normal"> Online</span>
              </h1>
            </div>

            <div
              aria-label="group of cards"
              className="overflow-y-hidden focus:outline-none mt-16 flex items-center justify-start lg:justify-center px-5 gap-4 pr-6"
            >
              {products.edges.map(({ node: product }, index) => {
                return (
                  <div
                    key={index}
                    aria-label="card 1"
                    className="focus:outline-none  h-full shadow-lg hover:shadow-xl cursor-pointer  mb-8 "
                  >
                    <Link to={`/shop/${product.metadata.url_slug}`}>
                      <div className="relative w-56 h-">
                        <img
                          alt={product.name}
                          role="img"
                          loading="lazy"
                          src={product.images[0]}
                          className="focus:outline-none h-[150px] w-full "
                        />
                        <div className="bg-white w-full">
                          <p className="focus:outline-none text-center leading-7 text-lg text-gray-900 py-8">
                            {product.name}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    />
  )
}
