import React from "react"
import Layout from "components/Layout"
import Products from "components/Products"
import Seo from "components/SEO"

const shop = () => {
  return (
    <div>
      <Seo
        title="Shop Online | Live Mealworms | Crickets | Frass | for Pets | Mandatory Insects"
        description="Shop Online | Pet food online"
      />
      <Layout>
        <Products />
      </Layout>
    </div>
  )
}

export default shop
